.sidebar {
  /* width: 200px; */
  height: 90vh;
  border-right: 1px solid gray;
  box-shadow: 5px 0px 24px 5px rgba(208, 216, 243, 0.6);
  background-color: rgb(249, 251, 253);
  /* position: fixed; */
}
@media screen and (min-width: 600px) {
  .flex-column {
    align-items: center;
    padding-top: 50px;
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
  }
}
